import { Logo } from '../Logo';
import styles from './Nav.module.scss';

const Nav = () => {

	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<a className={styles.logoLink} href='https://www.collab.land'>
					<Logo />
				</a>
			</div>
		</div>
	);
};

export default Nav;
