/* eslint-disable react-hooks/exhaustive-deps */
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { TeaserView } from './views';

function App() {

	return (
		<BrowserRouter>
			<Routes>
				<Route path='*' element={<TeaserView />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
