import { stars } from '../../images';
import { Mixpanel } from '../../mixpanel';
import React from 'react';
import styles from './IntroView.module.scss';

const IntroView: React.FunctionComponent = () => {
  // const screenSize = useScreenSize();

  // const isMobileScreen = ['mobile', 'tablet'].includes(screenSize);
  return (
    <>
      <div className={`${styles.page} ${styles.pageOne}`}>
        <div className={styles.emojiContainer}>
          <img
            className={styles.mascot}
            style={{ marginRight: '60px' }}
            width={500}
            src={stars}
            alt=''
          />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.pageTitle}>
            Introducing Collab.Land VIP
            <br />
          </div>
          <div className={styles.pagSecondaryTitle}>
            {' '}
            Premium Community-Building, Tailored for You
          </div>
          <p className={styles.pageDesc}>
            Welcome to the future of community development with Collab.Land VIP!
            <br />
            Projects striving for robust, engaged communities no longer need to
            navigate
            <br />
            the path alone.
          </p>
          <p className={styles.listHeading}>Here’s what our premium offering brings to the
            table:</p>
          <ul className={styles.pageList}>
            <li>
              <b>Bespoke Consulting:</b> Benefit from seasoned expertise to
              shape and sustain your community’s growth.
            </li>
            <br/>
            <li>
              <b>Weekly Touchpoints:</b> Consistent alignment with regular
              interactions to keep your community goals clear and achievable.
            </li>
            <br/>
            <li>
              <b>Priority Tech Support with CollabCare:</b> Skip the support
              queue. Get direct, prompt technical assistance whenever you need.
            </li>
            <br/>
            <li>
              <b>Customized Branding:</b> Elevate your community’s identity with
              a personalized verification bot and a tailored wallet connection
              page.
            </li>
            <br/>
            <li>
              <b>Mod & Admin Training:</b> Empower your team with the skills and
              knowledge to thrive.
            </li>
            <br/>
            <li>
              <b>Discord Security:</b> Ensure a safe environment with our
              comprehensive Discord server audit.
            </li>
          </ul>
          <p className={styles.pageDesc}>
            Collab.Land VIP isn’t just a service; it’s a transformative
            experience for projects serious about community excellence. Ready to
            elevate? Let’s shape your community narrative together.
          </p>
          <div className={styles.schedule}>
            Connect with Collab.Land VIP today and book your meeting!{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              className={styles.introLink}
              href='https://bit.ly/3KU3ql6'
              onClick={() => {
                Mixpanel.track('vip-collabland schedule-btn', {
                  button: 'schedule',
                });
              }}
            >
              Schedule
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroView;
